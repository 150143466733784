<nb-card>
  <nb-card-header *ngIf="entity" >Местонахождение {{entity}}</nb-card-header>
  <nb-card-body>
    <div class="row">
      <div class="col-sm-12 col-md-8">
        <div style="display: block;" id="map"></div>
      </div>
      <div *ngIf="entity" class="col-sm-12 col-md-4">
        <div class="font-row">
          <div class="header">
            <div class="name bold text-hint">Название {{entity}}</div>
            <h6>{{name}}</h6>
          </div>
          <p>
            {{addressName}}
          </p>
        </div>
      </div>
    </div>
  </nb-card-body>
</nb-card>
