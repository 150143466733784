import { Component, OnInit, Input } from '@angular/core';
import { Executor } from '../../../../../types';

@Component({
  selector: 'ngx-executor-cell',
  template: `{{renderValue}}` //{{value?.last_name}} {{value?.first_name}} {{value?.middle_name}}`,
})
export class ExecutorCellComponent implements OnInit {

  @Input()
  value: string | number;
  @Input()
  rowData: any;

  renderValue: string;

  constructor() { }

  ngOnInit() {
    this.renderValue = this.rowData['installername'] ? (this.rowData['installername'].last_name ? this.rowData['installername'].last_name + ' ' : '').concat(
      this.rowData['installername'].first_name ? this.rowData['installername'].first_name + ' ' : '',
      this.rowData['installername'].middle_name ? this.rowData['installername'].middle_name + ' ' : '') : '';
  }

}
