import { Injectable } from '@angular/core'
import { AbstractControl } from '@angular/forms';

@Injectable({providedIn: 'root'})
export class FormErrorsService {

  private readonly errorsMap = new Map([
    ['required', 'Это поле обязательно'],
  ])

  public hasError(control: AbstractControl): boolean {
    return control.touched && control.invalid
  }

  public getError(error: string): string {
    return this.errorsMap.get(error) || error
  }
}
