import { Component, Input } from '@angular/core'
import { AbstractControl } from '@angular/forms'

import { FormErrorsService } from '../../../services/form-errors/form-errors.service'

@Component({
  selector: 'ngx-form-errors',
  template: `
     <div *ngIf="control.touched" class="container">
        <div *ngFor="let err of control.errors | keyvalue" class="error">
          {{errorsService.getError(err.key)}}
        </div>
     </div>
  `,
  styles: [`
      .error {
        color: red;
        font-weight: 400;
        font-size: 12px;
      }
  `],
})
export class FormErrorsComponent {

  @Input()
  control: AbstractControl

  constructor(public errorsService: FormErrorsService) {
  }

}
