import { Injectable } from '@angular/core';
import { PushMessage } from '../../../types';
import { Subject } from 'rxjs';
import { getValueFormPushMessage } from '../../../utils';
import { NbToastrService } from '@nebular/theme';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class FromDeviceService {
  private _deviceCoords = new Subject();

  constructor(
    private authService: AuthService,
  ) { }

  get deviceCoords() {
    return this._deviceCoords.asObservable();
  }

  private _deviceImage$ = new Subject<{image: string}>();
  get deviceImage$() {
    return this._deviceImage$.asObservable();
  }

  public handleImage = (image: string) => {
    this._deviceImage$.next({ image: image });
  }

  public handleMessage = (message: PushMessage) => {
    if (message.includes('LAT')) {
      this.handleCoordsMessage(message);
    }

    if (message.includes('AUTH_TOKEN')) {
      this.handleAuthMessage(message);
    }
  }

 private handleAuthMessage = (message: PushMessage) => {
    const value = getValueFormPushMessage(message);

    if (value !== 'NONE') {
      this.authService.setToken(value);
      return;
    }

    this.authService.setToken(null);
  }

  private handleCoordsMessage = (message: PushMessage) => {
    const [lat, long] = message.split('|');

    this._deviceCoords.next(
      {
        lat: getValueFormPushMessage(lat),
        long: getValueFormPushMessage(long),
      },
    );
  }
}
