import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';


@Injectable()
export class HelperInterceptor implements HttpInterceptor {

  sub: Subscription;

  constructor(
    private authService: AuthService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const id = this.authService.getUserID();

    const request = req.clone({
      headers: req.headers.set('X-Login', String(id))});

    if (this.sub) {
      this.sub.unsubscribe();
    }

    return next.handle(request);
  }
}
