import { Component, OnInit } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';
declare var webkit: any;

@Component({
  selector: 'ngx-only-web',
  template: `
      <div class="menu-sidebar">
          <nb-menu [items]="menu"></nb-menu>
      </div>
      <div class="only-web">
          Для вашей роли в системе доступна только web - версия интерфейса.
      </div>
  `,
  styleUrls: [`./only-web.component.scss`],
})
export class OnlyWebComponent implements OnInit {

  menu: NbMenuItem[] = []

  public ngOnInit(): void {
    this.menu = [
      {
        title: 'Выйти',
        icon: 'log-out-outline',
        link: '/auth/login',
        pathMatch: 'full',
      },
    ]
    if (document.getElementById('nb-global-spinner')) {
      document.getElementById('nb-global-spinner').remove();
    }
  }
}
