import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment/moment';

@Pipe({ name: 'minutesToHHMM' })
export class DatePipe implements PipeTransform {
    transform(minutes: number): string {
      const hours = Math.floor(minutes / 60).toString().padStart(2, '0')
      const mins = Math.floor(minutes % 60).toString().padStart(2, '0')

      return `${hours}:${mins}`
    }
}
