import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { TypeMessageFromDevice } from '../../../types';


@Injectable({
  providedIn: 'root'
})
export class PushService {
  _timeoutID: number;
  _timeout: Subject<string> = new Subject<string>();

  get timeout() {
    return this._timeout.asObservable();
  }

  startTimer(type: TypeMessageFromDevice) {
    window.clearTimeout(this._timeoutID);

    this._timeoutID = window.setTimeout(() => {
      const errorMessage = type === 'push' ?
        'У вас не будут показываться пуш уведомления, так как вы не включили их в настройках' :
        null;

      this._timeout.next(errorMessage);
    }, 5000);
  }
}
