import { ErrorHandler, Injectable } from '@angular/core'
import * as Sentry from '@sentry/browser'

import { environment } from '../../environments/environment'


@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  private readonly skipUrls = ['create-from-excel', 'api/reports', 'api/posms', 'api/stores']

  constructor() {
    if (location.hostname !== 'localhost' && location.hostname !== '127.0.0.1') {
      Sentry.init({
        dsn: 'https://1c1f7257fe4e3e92f779c4c393aa56e9@sentry.action.ru/3',
        environment: environment.name,
      })
    }
  }

  private shouldSkipError(error): boolean {
    return this.skipUrls.some(url => error?.url?.includes(url) || false)
  }

  handleError(error) {
    const skipError = this.shouldSkipError(error)
    if (skipError) return

    console.log('Capturing sentry error: ', error)

    Sentry.captureException(error.originalError || error)
    console.error(error)
  }
}
