import {Component, OnInit, Input, ChangeDetectorRef} from '@angular/core';
import {OrderResponse, StatusType} from '../../../../../types';
import {ViewCell} from "ng2-smart-table";
import {BehaviorSubject, forkJoin, of, Subject} from "rxjs";
import {catchError, delay, map} from "rxjs/operators";
import {CrudService} from "../../../../services/crud/crud.service";
import {NbDialogService, NbToastrService} from "@nebular/theme";
import {AuthService} from "../../../../services/auth/auth.service";
import {ApproveReasonDialogComponent} from "../../dialogs/approve-reason-dialog/approve-reason-dialog.component";

@Component({
  selector: 'ngx-approved-cell',
  template: `
<!--    <button (click)="decline()" [disabled]="workingWithOrderInProgress | async" nbButton type="button"-->
<!--            status="danger" style="margin-left: 10px">-->
<!--      <nb-icon icon="close"></nb-icon>-->
<!--    </button>-->
    <div *ngIf="(showStatusKind | async) === 'text'">
      {{ statusText | async }}
    </div>
    <div *ngIf="(showStatusKind | async) === 'buttons'" style="display: flex">
      <button (click)="approve()" [disabled]="workingWithOrderInProgress | async" nbButton type="button"
              status="primary"
              style="background-color: green; border-color: green;"
      >
        <nb-icon icon="checkmark"></nb-icon>
        <!--        {{ (workingWithOrderInProgress | async) ? 'Подождите...' : 'Утвердить заявку' }}-->
      </button>
      <button (click)="decline()" [disabled]="workingWithOrderInProgress | async" nbButton type="button"
              status="danger" style="margin-left: 10px">
        <nb-icon icon="close"></nb-icon>
        <!--        {{ (workingWithOrderInProgress | async) ? 'Подождите...' : 'Отменить заявку' }}-->
      </button>
    </div>
  `,
})
export class ApprovedCellComponent implements ViewCell, OnInit {

  @Input()
  value: string | number;
  @Input()
  rowData: any;
  runStatus = '';
  currentRole = '';
  currentUserId: number = null;
  public showStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showApproveButton: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public workingWithOrderInProgress: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public showStatusKind: BehaviorSubject<'none' | 'buttons' | 'text'> = new BehaviorSubject<"none" | "buttons" | "text">('none');
  public statusText: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(private crudService: CrudService,
              private toastr: NbToastrService,
              private authService: AuthService,
              private dialogService: NbDialogService
  ) {

  }

  ngOnInit() {
    this.updateStatus();


    this.getAuthData();
    this.setShowStatus();
  }

  updateStatus() {
    this.runStatus = this.rowData['runtstatus'];
    this.showStatus.next(this.rowData['status'] === 'DONE' && this.rowData['approved_by_creator'] !== null);
    this.showApproveButton.next(this.rowData['status'] === 'DONE' && this.rowData['approved_by_creator'] === null);

    this.statusText.next(this.rowData['rstatus_comment']);

    /*
    if (this.runStatus === '10 NEW' && this.currentRole === 'CLIENT') {
      this.statusText.next('');
    }
    if (this.runStatus === '10 NEW' && this.currentRole !== 'CLIENT') {
      return this.rowData['approved_by_creator'] === null ? this.statusText.next('Утверждается клиентом') : this.rowData['approved_by_creator'] ? this.statusText.next('Утверждено клиентом') : this.statusText.next('Отклонено клиентом');
    }

    if (this.runStatus === '35 DONE_ACTION_REVIEW' && this.currentRole === 'ACTION') {
      this.statusText.next('');
    }
    if (this.runStatus === '35 DONE_ACTION_REVIEW' && this.currentRole !== 'ACTION') {
      return this.rowData['approved_by_creator'] === null ? this.statusText.next('Подтверждается Action') : this.rowData['approved_by_creator'] ? this.statusText.next('Подтверждено Action') : this.statusText.next('Отклонено Action');
    }
    if (this.runStatus === '37 DONE_CLIENT_REVIEW' && this.currentRole === 'CLIENT') {
      this.statusText.next('');

    }
    if (this.runStatus === '37 DONE_CLIENT_REVIEW' && this.currentRole !== 'CLIENT') {
      return this.rowData['approved_by_creator'] === null ? this.statusText.next('Утверждается клиентом') : this.rowData['approved_by_creator'] ? this.statusText.next('Утверждено клиентом') : this.statusText.next('Отклонено клиентом');
    }
    return this.rowData['approved_by_creator'] === null ? this.statusText.next('Утверждается') : this.rowData['approved_by_creator'] ? this.statusText.next('Утверждено') : this.statusText.next('Отклонено');
    */

  }

  getAuthData() {
    this.authService.roleId.subscribe(
      item => {
        this.currentRole = item.role;
        this.currentUserId = item.id;
        this.setShowStatus();
        this.updateStatus();
      }
    );
  }

  setShowStatus() {
    if ((this.runStatus === '10 NEW' && this.currentRole === 'CLIENT' && this.currentUserId === this.rowData['created_for']['id']) ||
      (this.runStatus === '35 DONE_ACTION_REVIEW' && this.currentRole === 'ACTION') ||
      (this.runStatus === '37 DONE_CLIENT_REVIEW' && this.currentRole === 'CLIENT')
    ) {
      this.showStatusKind.next('buttons');

    } else {
      this.showStatusKind.next('text');

    }
  }


  get state(): string {
    switch (this.rowData['approved_by_creator']) {
      case true:
        return "ДА";
      case false:
        return "НЕТ";
      default:
        return 'Не знаю';
    }
  }

  public approve() {
    this.workingWithOrderInProgress.next(true);

    forkJoin([
      this.crudService.sendPostV2<OrderResponse>(`/orders/${this.rowData['id']}/taskoper/yes`, {"reason": ''}).pipe(
        map(res => ({res, err: null})),
        catchError(err => of({res: null, err})),
      ),
      of("Waiting For Request").pipe(delay(2000)),
    ]).subscribe(([result, interval]) => {

      this.workingWithOrderInProgress.next(false); // = false;
      if (result.res) {
        this.rowData['approved_by_creator'] = true;
        this.rowData['runtstatus'] = result.res['runtstatus'];
        this.rowData['rstatus_comment'] = result.res['rstatus_comment'];
        this.updateStatus();
        this.setShowStatus();
        this.toastr.success("Заявка утверждена!");
      } else {
        this.toastr.warning("Ошибка утверждения заявки. Пожалуйста, попробуйте еще раз. Если эта ошибка повториться, обратитесь в техподдержку");
      }

    });
  }

  public decline() {
    this.workingWithOrderInProgress.next(true);
    this.dialogService.open(ApproveReasonDialogComponent).onClose.subscribe(result => {
      if (result.code === 'OK') {
        this.declineTask(result.reason);
      } else if (result.code === 'CANCEL') {
        this.workingWithOrderInProgress.next(false);
      }
    });

  }

  declineTask(reason: string) {
    forkJoin([
      this.crudService.sendPostV2<OrderResponse>(`/orders/${this.rowData['id']}/taskoper/no`, {"reason": reason}).pipe(
        map(res => ({res, err: null})),
        catchError(err => of({res: null, err})),
      ),
      of("Waiting For Request").pipe(delay(2000)),
    ]).subscribe(([result, interval]) => {

      this.workingWithOrderInProgress.next(false); // = false;
      if (result.res) {
        this.rowData['approved_by_creator'] = false;
        this.rowData['runtstatus'] = result.res['runtstatus'];
        this.rowData['rstatus_comment'] = result.res['rstatus_comment'];
        this.updateStatus();
        this.setShowStatus();
        this.toastr.success("Заявка отклонена!");
      } else {
        this.toastr.warning("Ошибка утверждения заявки. Пожалуйста, попробуйте еще раз. Если эта ошибка повториться, обратитесь в техподдержку");
      }

    });
  }
}
