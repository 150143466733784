import { Component, OnInit, Input } from '@angular/core';
import { PosmResponse, PosmWithCount } from '../../../../types';

@Component({
  selector: 'ngx-selected-posms-table',
  templateUrl: './selected-posms-table.component.html',
  styleUrls: ['./selected-posms-table.component.scss']
})
export class SelectedPosmsTableComponent implements OnInit {

  @Input()
  list: PosmWithCount[] = [];

  settings = {
    attr: {
      class: 'table',
    },
    mode: 'external',
    // mode: 'inline',
    noDataMessage: 'Нет данных',
    pager: {
      display: true,
      perPage: 5,
    },
    actions: false,
    columns: {
      sku: {
        title: 'Артикул',
        type: 'string',
      },
      name: {
        title: 'Название',
        type: 'string',
      },
      count: {
        title: 'Остатки',
        type: 'string',
      },
    },
  };

  source = {

  }
  constructor() { }

  ngOnInit() {
  }

}
