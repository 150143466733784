import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ngx-task-cell',
  styleUrls: ['./task-cell.component.scss'],
  template: `
  <a routerLink="/pages/orders/task/{{rowData.id}}" class="status {{classNameByStatus}}">
    Подробнее
  </a>
  `
})
export class TaskCellComponent implements OnInit {
  currentStatus = '';
  classNameByStatus = 'new';

  @Input()
  rowData: any;

  constructor() { }

  ngOnInit() {
  }

}
