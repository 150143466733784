import { Component, OnInit, Output, EventEmitter, OnDestroy, AfterViewInit } from '@angular/core';
import { FromDeviceService } from '../../../services/fromDevice/from-device.service';
import { Subscription } from 'rxjs';
import { AddressFromModal } from '../../../../types';
import { PushService } from '../../../services/push/push.service';
import { NbToastrService } from '@nebular/theme';

@Component({
  selector: 'ngx-address-modal',
  templateUrl: './address-modal.component.html',
  styleUrls: ['./address-modal.component.scss'],
})
export class AddressModalComponent implements OnInit, OnDestroy, AfterViewInit {
  private yMap;
  private sub: Subscription;
  private subPush: Subscription;

  public addressName;
  public addressLat = 59.939095;
  public addressLong = 30.315868;
  public loading = false;
  public draggable = false;

  constructor(
    private formDeviceService: FromDeviceService,
    private pushService: PushService,
    private toastr: NbToastrService,
  ) { }

  @Output()
  public setAddress: EventEmitter<AddressFromModal> = new EventEmitter<AddressFromModal>();

  @Output()
  public close: EventEmitter<void> = new EventEmitter<void>();

  public handleClose = () => {
    this.close.emit();
  }

  public getGeoLocation() {
    this.loading = true;

    (window as any).getGeoLocation();
  }

  private updateAddressData = async (coords: number[]) => {
    this.loading = true;
    try {
      const response = await ymaps.geocode(coords);
      const [lat, long] = coords;
      const nearest = response.geoObjects.get(0);
      const name = nearest.properties.get('text');

      this.addressLat = lat;
      this.addressLong = long;
      this.addressName = name;

      this.loading = false;
    } catch (error) {
      this.loading = false;
      console.error(error);
    }
  }

  private handleBeginAction = () => {
    this.draggable = true;
  }

  private handleEndAction = () => {
    const coords = this.yMap.getCenter();

    this.updateAddressData(coords);

    this.draggable = false;
  }

  private initMap = () => {
    this.yMap = new ymaps.Map('map', {
      center: [this.addressLat, this.addressLong],
      zoom: 10,
      autoFitToViewport: 'always',
      controls: ['zoomControl'],
    }, {
      suppressMapOpenBlock: true,
    });

    this.yMap.events.add('actionbegin',  this.handleBeginAction);
    this.yMap.events.add('actionend',  this.handleEndAction);
  }

  public save = () => {
    const { addressName, addressLat, addressLong } = this;

    this.setAddress.emit({
      addressName,
      addressLat,
      addressLong,
    });
  }

  ngAfterViewInit() {
    ymaps.ready(() => this.initMap());
  }

  ngOnInit() {
    this.sub = this.formDeviceService.deviceCoords.subscribe(({
      lat,
      long,
    }) => {
      this.loading = false;

      this.yMap.setCenter([lat, long]);
      this.updateAddressData([lat, long]);
    });

    // Вызывается когда нет ответа от девайса по местоположению
    this.subPush = this.pushService.timeout.subscribe(() => {
      try {
        const geoSuccess = position => {
          const lat = position.coords.latitude;
          const long = position.coords.longitude;

          this.yMap.setCenter([lat, long]);
          this.updateAddressData([lat, long]);
        };
        navigator.geolocation.getCurrentPosition(geoSuccess);
      } catch (err) {
        this.toastr.warning('Не удалось получить геолокацию');
        console.log(err, 'GETTING NAVIGATION FROM BROWSER API');
      }
    });

    (window as any).getGeoLocation();
  }

  ngOnDestroy() {
    this.sub && this.sub.unsubscribe();
    this.subPush && this.subPush.unsubscribe();
    this.yMap && this.yMap.events.remove('actionend', this.handleEndAction);
    this.yMap && this.yMap.events.remove('actionbegin', this.handleBeginAction);
    this.yMap.destroy && this.yMap.destroy();
  }
}
