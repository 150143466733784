import { Component, OnInit, Input } from '@angular/core';
import { StatusType } from '../../../../../types';

@Component({
  selector: 'ngx-status-cell',
  styleUrls: ['./status-cell.component.scss'],
  template: `
  <div class="status {{classNameByStatus}}">
    {{currentStatus}}
  </div>
  `,
})
export class StatusCellComponent implements OnInit {
  private status = {
    'NEW': 'в обработке',
    'IN_WORK': 'в реализации',
    'DECLINED': 'отклонена',
    'DONE': 'выполнена',
    'ROUTE_CREATED': 'маршрут назначен',
    'IRRELEVANT': 'Не актуальна',
  };

  currentStatus = '';
  classNameByStatus = '';

  @Input()
  value?: StatusType = 'NEW';

  constructor() { }

  ngOnInit() {
    this.currentStatus = this.status[this.value];
    this.classNameByStatus = this.value ? this.value.toLocaleLowerCase() : '';
  }
}
