import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-address-cell',
  template: `
  <div><strong>{{value.name}}</strong></div>
  {{value.address_name}}
  `
})
export class AddressCellComponent implements OnInit {
  @Input() public rowData: any;

  @Input() public value: {name: string; address_name: string}

  public ngOnInit(): void {
    if (this.rowData && this.rowData.store) {
      this.value = this.rowData.store
    } else {
      this.value = {name: '', address_name: ''}
    }
  }
}
