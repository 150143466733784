import { Component, OnInit, Input, OnDestroy, AfterViewInit, OnChanges, SimpleChanges } from "@angular/core";

@Component({
  selector: "ngx-map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.scss"],
})
export class MapComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  yMap;

  @Input()
  entity: string;

  @Input()
  name: string;

  @Input()
  addressName: string;

  @Input()
  addressLat: number;

  @Input()
  addressLong: number;

  constructor() {}

  private initMap = () => {
    this.yMap = new ymaps.Map("map", {
      center: [this.addressLat, this.addressLong],
      zoom: 10,
      autoFitToViewport: 'always',
    });

    this.yMap.geoObjects.add(
      new ymaps.Placemark(
        [this.addressLat, this.addressLong],
        {
          balloonContent: this.addressName,
        },
        {
          preset: "islands#icon4s",
          iconColor: "#e52f30",
        },
      ),
    );
  };

  ngOnChanges() {
    if (this.yMap) {
      this.yMap.destroy();
      this.initMap();
    }
  }

  ngAfterViewInit() {
    ymaps.ready(() => this.initMap());
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.yMap && this.yMap.destroy && this.yMap.destroy();
  }
}
