<div [class.loading]="loading" class="address-modal">
  <div (click)="handleClose()" class="close-button"><i class="nb-close"></i></div>
  <div class="map-pin">
    <img [class.draggable]="draggable" class="map-image" src="/assets/images/pin.svg" />
  </div>
  <div style="display: block;" id="map"></div>
  <div class="title">{{addressName}}</div>
  <div [class.opacity]="loading" class="container">
    <button [disabled]="!addressName" (click)="save()" status="primary" nbButton type="button">Создать торговую точку</button>
    <button [disabled]="loading" (click)="getGeoLocation()" class="find-geo-button" type="button">
      <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'><path fill-rule='evenodd' d='M10.277 15.212l10.052-4.188a.495.495 0 0 1 .646.647l-4.188 10.053a.495.495 0 0 1-.916-.007l-1.597-3.99-3.99-1.597a.495.495 0 0 1-.008-.918z'/></svg>
    </button>
  </div>
</div>
