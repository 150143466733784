/*
 Local environment variables
 for local development
*/

export const environment = {
  production: true,
  version: (window as any).sVersion,
  apiUrl: `https://api.techmerch-demo.cloudstaffing.ru/api`,
  apiUrlV2: `https://api.techmerch-demo.cloudstaffing.ru/v2/api`,
  name: 'demo',
};
