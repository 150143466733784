import { AfterViewInit, Directive, ElementRef } from '@angular/core'


@Directive({
  selector: '[requiredTag]',
})
export class RequiredTagDirective implements AfterViewInit {
  private readonly tag = ' <span style="color: red;">*</span>'

  constructor(private elementRef: ElementRef) {
  }

  public ngAfterViewInit(): void {
    this.elementRef.nativeElement.innerHTML += this.tag
  }
}
