import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  canActivate() {
    const authToken  = this.authService.getToken();
    console.log('AUTHGUARD');

    if (authToken && typeof authToken === 'string' && authToken.length > 6) {
      console.log('AUTHGUARD TOKEN EXIST');

      return true;
    }

    return this.authService.authToken.pipe(
      map(token => {
        console.log(token, 'token from AuthGuard');

        if (token) {
          return true;
        }

        console.log('CLEAR TOKEN ON AUTH GUARD');

        if (localStorage) {
          localStorage.clear();
        }

        console.log(window.location.href, 'LOCATION');

        return this.router.createUrlTree(['/auth/login']);
      })
    );
  }
}
