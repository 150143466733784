import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';

import { AuthService } from '../../services/auth/auth.service';


@Component({
  selector: 'ngx-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss'],
})
export class DeleteAccountComponent {
  deleted: boolean

  form: FormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(2)]],
  })

  constructor(
    private fb: FormBuilder,
    private toastr: NbToastrService,
    private authService: AuthService,
  ) {
  }

  public onSubmit(): void {
    if (this.form.invalid) {
      this.toastr.danger('Форма не валидна', '', { status: 'success', hasIcon: false });
      return
    }

    this.authService.deleteAccount(this.form.value)
      .subscribe({
        next: () => {
          this.toastr.success('Аккаунт деактивирован', 'Успешно')
          this.deleted = true
        },
        error: () => {
          this.toastr.danger(
            'Не удалось деактивировать аккаунт, проверьте вводимые данные или попробуйте позднее',
            'Ошибка'
          )
        }
      })
  }
}
