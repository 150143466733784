import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { RolesTranslations } from '../../../../utils';
import { CrudService } from '../../../services/crud/crud.service';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PagedResponseV2 } from '../../../../types';


@Component({
  selector: 'ngx-mobile-list',
  templateUrl: './mobile-list.component.html',
  styleUrls: ['./mobile-list.component.scss'],
})
export class MobileListComponent implements OnInit, OnDestroy {
  private subSearch: Subscription;
  private searchRequestSub: Subscription;
  private sub: Subscription;
  private subNext: Subscription;
  private page = 1;
  private limit = 15;

  public infiniteScrollDistance = 2;
  public infiniteScrollThrottle = 15;
  public form: FormGroup;
  public rolesTranslations = RolesTranslations;
  public data = [];
  public loading = true;

  @Input()
  public list: any[];

  @Input()
  public type: 'address' | 'users' | 'claim' | 'clients' | 'stores' | 'programs' = 'address';

  @Input()
  public api: string;

  @Output()
  public onCreate = new EventEmitter();

  @Output()
  public onGoToDetail = new EventEmitter<number>();

  @Output()
  public onEdit = new EventEmitter<any>();

  @Output()
  public onDelete = new EventEmitter<any>();

  constructor(
    private crudService: CrudService,
    private fb: FormBuilder,
  ) {
    this.form = this.fb.group({
      search: '',
    });
  }

  public handleCreate() {
    this.onCreate.emit();
  }

  public handleGoToDetail(entity) {
    this.onGoToDetail.emit(entity);
  }

  public handleDelete(entity) {
    this.onDelete.emit(entity);
  }

  public handleEdit(entity) {
    this.onEdit.emit(entity);
  }

  public handleSuccess = res => {
    this.loading = false;

    if (res && res.results && res.results.length > 0) {
      this.page = this.page + 1;
      this.data.push(...res.results);
    }
  }

  public handleSearch = () => {
    this.page = 1;
    this.data = [];

    this.searchRequestSub = this.crudService.getV2<PagedResponseV2<any>>(this.api, this.getParams()).subscribe(res => {
      if (res && res.results && res.results.length > 0) {
        this.data.push(...res.results);
      }
    });
  }

  private handleError = () => this.loading = false;

  private getParams = () => `_sort=id&_order=DESC&name_match=${this.form.get('search').value}&_page=${this.page}&&_limit=${this.limit}`;

  public loadNext() {
    if (this.loading) { return; }

    this.loading = true;

    this.subNext = this.crudService.getV2<PagedResponseV2<any>>(this.api, this.getParams())
      .subscribe(
        this.handleSuccess,
        this.handleError,
      );
  }

  ngOnInit() {
    this.sub = this.crudService.getV2<PagedResponseV2<any>>(this.api, this.getParams())
      .subscribe(
        this.handleSuccess,
        this.handleError,
      );

    this.subSearch = this.form.get('search').valueChanges.pipe(
      debounceTime(500),
    ).subscribe(this.handleSearch);
  }

  ngOnDestroy() {
    this.sub && this.sub.unsubscribe();
    this.subNext && this.subNext.unsubscribe();
    this.subSearch && this.subSearch.unsubscribe();
    this.searchRequestSub && this.searchRequestSub.unsubscribe();
  }
}
