import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbDateFnsDateModule } from '@nebular/date-fns';

import {
  NbCardModule,
  NbIconModule,
  NbButtonModule,
  NbAccordionModule,
  NbListModule,
  NbInputModule,
  NbSpinnerModule,
  NbSelectModule,
  NbDialogModule,
  NbDatepickerModule,
  NbCheckboxModule,
} from '@nebular/theme';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgSelectModule } from '@ng-select/ng-select';
import { Ng2SmartTableModule } from 'ng2-smart-table';

import { MapComponent } from './components/map/map.component';
import { StoresFormComponent } from './components/stores-form/stores-form.component';
import { ThemeModule } from '../@theme/theme.module';
import { MobileListComponent } from './components/mobile-list/mobile-list.component';
import { GetName } from './pipes/get-name-on-mobile-list/get-name-on-mobile-list';
import { GetSrc } from './pipes/get-src/get-src';
import { SelectedPosmsTableComponent } from './components/selected-posms-table/selected-posms-table.component';
import { AddressModalComponent } from './components/address-modal/address-modal.component';
import { MobileOrderListItemComponent } from './components/mobile-order-list-item/mobile-order-list-item.component';
import { AddressCellComponent } from './components/order-cells/address-cell/address-cell.component';
import { ExecutorCellComponent } from './components/order-cells/executor-cell/executor-cell.component';
import { PosmCellComponent } from './components/order-cells/posm-cell/posm-cell.component';
import { CityCellComponent } from './components/order-cells/city-cell/city-cell.component';
import { CreatedByCellComponent } from './components/order-cells/created-by-cell/created-by-cell.component';
import { StatusCellComponent } from './components/order-cells/status-cell/status-cell.component';
import { TaskCellComponent } from './components/order-cells/task-cell/task-cell.component';
import { WorksTypeCellComponent } from './components/order-cells/works-type-cell/works-type-cell.component';
import { CreatedAtCellComponent } from './components/order-cells/created-at-cell/created-at-cell.component';
import { DateCellComponent } from './components/order-cells/date-cell/date-cell.component';
import { CountInArrayPipe } from './pipes/count-in-array/count-in-array.pipe';
import { StoresFormModalComponent } from './components/stores-form/stores-form.modal.component';
import { BaseStoreFormComponent } from './components/stores-form/base-stores-form.component';
import { ApprovedCellComponent } from './components/order-cells/approved-cell/approved-cell.component';
import { ApproveReasonDialogComponent } from './components/dialogs/approve-reason-dialog/approve-reason-dialog.component';
import { FormErrorsComponent } from './components/form-errors/form-errors.component';
import { RequiredTagDirective } from './directive/required-tag.directive';
import { AcceptExecutionComponent } from '../pages/orders/components/accept-execution/accept-execution.component';
import { BlankRunComponent } from '../pages/orders/components/blank-run/blank-run.component';
import { GetImageByIdWithTokenPipe } from './pipes/http-get-url-secure/http-get-url-secure';
import { BlobToImageSrcPipe } from './pipes/blob-to-image-src';
import { DatePipe } from './pipes/date-pipe';
import { ArrayMapToPipe } from './pipes/count-in-array/array-map-to.pipe';

@NgModule({
  declarations: [
    MapComponent,
    AcceptExecutionComponent,
    MobileListComponent,
    GetName,
    GetSrc,
    GetImageByIdWithTokenPipe,
    DatePipe,
    StoresFormComponent,
    StoresFormModalComponent,
    SelectedPosmsTableComponent,
    AddressModalComponent,
    MobileOrderListItemComponent,
    AddressCellComponent,
    ExecutorCellComponent,
    PosmCellComponent,
    StatusCellComponent,
    TaskCellComponent,
    WorksTypeCellComponent,
    CityCellComponent,
    CreatedByCellComponent,
    CreatedAtCellComponent,
    DateCellComponent,
    ApprovedCellComponent,
    CountInArrayPipe,
    ArrayMapToPipe,
    DatePipe,
    BaseStoreFormComponent,
    ApproveReasonDialogComponent,
    FormErrorsComponent,
    RequiredTagDirective,
    BlankRunComponent,
    BlobToImageSrcPipe,
  ],
  // TODO: Выпилить импортировные модули @nebular/theme' из модулей где юзается SharedModule
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NbAccordionModule,
    NbSelectModule,
    ThemeModule,
    NbIconModule,
    NbListModule,
    NbCardModule,
    Ng2SmartTableModule,
    NbSpinnerModule,
    CommonModule,
    NbButtonModule,
    NbCheckboxModule,
    InfiniteScrollModule,
    NbEvaIconsModule,
    NbInputModule,
    NgSelectModule,
    RouterModule,
    NbDialogModule.forRoot(),
    NbDatepickerModule,
    NbDateFnsDateModule.forRoot({
      parseOptions: { awareOfUnicodeTokens: true },
      formatOptions: { awareOfUnicodeTokens: true },
    }),
  ],
  providers: [
  ],
  exports: [
    MapComponent,
    AcceptExecutionComponent,
    BlankRunComponent,
    NbAccordionModule,
    MobileListComponent,
    GetName,
    GetSrc,
    NbCardModule,
    NbCheckboxModule,
    StoresFormComponent,
    StoresFormModalComponent,
    NgSelectModule,
    NbDialogModule,
    SelectedPosmsTableComponent,
    MobileOrderListItemComponent,
    AddressCellComponent,
    ExecutorCellComponent,
    PosmCellComponent,
    StatusCellComponent,
    TaskCellComponent,
    WorksTypeCellComponent,
    CityCellComponent,
    CreatedByCellComponent,
    CreatedAtCellComponent,
    DateCellComponent,
    ApprovedCellComponent,
    CountInArrayPipe,
    BaseStoreFormComponent,
    RequiredTagDirective,
    NbDatepickerModule,
    NbInputModule,
    NbButtonModule,
    GetImageByIdWithTokenPipe,
    DatePipe,
    BlobToImageSrcPipe,
    ArrayMapToPipe,
  ],
})
export class SharedModule { }
