import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';


@Component({
  templateUrl: './blank-run.component.html',
  styleUrls: ['./blank-run.component.scss']
})
export class BlankRunComponent {
  public comment: string = '';

  constructor(
    protected dialogRef: NbDialogRef<BlankRunComponent>,
  ) {
  }

  public save(): void {
    this.dialogRef.close(this.comment)
  }

  public close(): void {
    this.dialogRef.close()
  }
}
