import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

import { HttpErrorHandlerService } from '../services/http-error-handler/http-error-handler.service';
import { parseUrl } from '../../utils';
import { AuthService } from '../services/auth/auth.service';
import { EServerErrors } from '../../types/EServerErrors';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  constructor(
    private httpErrHandler: HttpErrorHandlerService,
    private authService: AuthService,
    private router: Router,
  ) {
  }

  private handleError(error: HttpErrorResponse, request: HttpRequest<any>): Observable<HttpErrorResponse | any> {
    console.log('error:', error)
    console.log('request:', request)

    const { status } = error;
    const isUserNotAuthenticated = status === EServerErrors.unathorized;
    const parsedUrl = parseUrl(request.url);
    const url = parsedUrl && parsedUrl.pathname || "";

    if (isUserNotAuthenticated) {
      this.authService.clearToken()
      this.router.navigateByUrl('/auth/login').then()
    }

    if (error?.error instanceof Blob) {
      this.httpErrHandler.readBlobError(error, url, status)
    } else {
      this.httpErrHandler.showV2ServerError(error?.error, url, status)
    }

    return throwError(error)
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();

    if (token) {
      request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
    }

    return next.handle(request).pipe(catchError(error => this.handleError(error, request)))
  }
}
