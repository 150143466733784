import {Component, OnInit, TemplateRef} from '@angular/core';
import {NbDialogRef, NbDialogService} from "@nebular/theme";

@Component({
  selector: 'ngx-approve-reason-dialog',
  templateUrl: './approve-reason-dialog.component.html',
  styleUrls: ['./approve-reason-dialog.component.scss']
})
export class ApproveReasonDialogComponent implements OnInit {
  reason = '';
  constructor(protected dialogRef: NbDialogRef<ApproveReasonDialogComponent>) { }

  ngOnInit() {
  }

  submit(){
    this.dialogRef.close({code: 'OK', reason:  this.reason});

  }
  close() {
    this.dialogRef.close({code: 'CANCEL', reason:  this.reason});
  }
}
