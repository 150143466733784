import { Injectable } from '@angular/core';
import head from 'lodash/head';
import get from 'lodash/get';
import { Router } from '@angular/router';

import { isMobileDevice } from '../../../utils';
import { ROLE_VALUE, UserResponse } from '../../../types';


@Injectable({ providedIn: 'root' })
export class RedirectService {

  constructor(private router: Router) {
  }

  public redirectDesktop(role: ROLE_VALUE): void {
    switch (role) {
      case 'COORDINATOR':
      case 'CLIENT':
      case 'ACTION':
        this.router.navigate(['/pages/orders/list']).then()
        break
      case 'INSTALLER':
        this.router.navigate(['/pages/route/my']).then()
        break
      case 'SUPER_ADMIN':
      case 'SENIOR_INSTALLER':
        this.router.navigate(['/pages/orders/list']).then()
    }
  }

  public redirect(user: UserResponse): void {
    const role = head(get(user, 'roles'));
    if (isMobileDevice()) {
      console.log('mobile device redirect')
      if (role !== 'CLIENT' && role !== 'INSTALLER' && role !== 'ACTION') {
        this.router.navigate(['/only-web']).then()
      } else if (role === 'CLIENT' || role === 'ACTION') {
        this.router.navigate(['/pages/orders/list']).then()
      } else if (role === 'INSTALLER') {
        this.router.navigate(['/pages/route/my']).then()
      }
    } else {
      console.log('desktop device redirect')
      this.redirectDesktop(role)
    }
  }
}
