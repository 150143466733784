<nb-card>
  <nb-card-header>
    Обновить количество
  </nb-card-header>
  <nb-card-body>
    <div class="wrapper">
      <p>{{ posm?.name }}</p>
      <div class="form-group">
        <div class="label">Вид работ</div>
        <p>{{ posm?.workType }}</p>
      </div>
      <div class="form-group">
        <div class="label">Количество</div>
        <p>{{ posm?.count }}</p>
      </div>
      <div class="form-group count">
        <label for="name" class="label">Количество фактически</label>
        <input id="name"
               type="number"
               nbInput
               min="0"
               placeholder="кол-во"
               [(ngModel)]="count"/>
      </div>
      <button [disabled]="count <= 0"
              nbButton
              status="danger"
              (click)="save()"
      >Сохранить</button>
    </div>
  </nb-card-body>
</nb-card>
