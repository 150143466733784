<nb-card>
  <nb-card-header>Отклонение заявки</nb-card-header>
  <nb-card-body>
    <div>
      Причина
    </div>
    <input id="industry" type="text" nbInput fullWidth placeholder="Причина"  [(ngModel)]="reason" style="margin-top: 10px"/>
  </nb-card-body>
  <nb-card-footer>
    <button nbButton (click)="close()">Закрыть</button>
    <button nbButton (click)="submit()" [disabled]="!this.reason" style="margin-left: 10px">Отклонить</button>
  </nb-card-footer>
</nb-card>
