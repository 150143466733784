<nb-layout>
  <nb-layout-column>
    <div class="row form-wrapper">
      <div class="col-md-6 offset-md-3 offset-sm-0">
        <div class="wrapper">
          <h3>Удаление аккаунта в Action Tech Merch</h3>
          <ng-container *ngIf="!deleted; else info">
            <span class="description">
            <p>
              Чтобы удалить свой аккаунт введите имя пользователя, пароль и нажмите на кнопку "Удалить аккаунт".
              После нажатия на кнопку "Удалить аккаунт" вход в приложение будет незамедлительно закрыт,
              а все данные будут удалены в течение 30 дней
            </p>
          </span>

            <form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">
              <div class="input-container">
                <input nbInput fullWidth formControlName="email" class="form-input" type="text" placeholder="email">
              </div>
              <div class="input-container">
                <input nbInput fullWidth formControlName="password" class="form-input" type="password"
                       placeholder="password" required="">
              </div>
              <div class="form-button-container">
                <button class="form-button" type="submit">Удалить аккаунт</button>
                <span class="form-text-divider"></span>
                <a class="form-link" [routerLink]="'/auth/login'">Отмена</a>
              </div>
            </form>
          </ng-container>
          <ng-template #info>
            <div class="account-deleted">
              <h5>
                <img src="/assets/images/account-deleted.svg" alt="Иконка удаления">
                Аккаунт удалён
              </h5>
              <p>Ваш аккаунт успешно удалён! Спасибо что были с нами.</p>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </nb-layout-column>
</nb-layout>
