import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { IdResponse, IExecutionPart } from '../../../types';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

const API_URL_V2 = environment.apiUrlV2;

@Injectable({
  providedIn: 'root',
})
export class ImageUploadService {
  ids: number[] = [];

  constructor(
    private http: HttpClient,
  ) { }

  public uploadImagesByWorkType(id: number, workType: string, imageIds: number[]): Observable<IExecutionPart[]> {
    return this.http.post<IExecutionPart[]>(
      `${API_URL_V2}/orders/${id}/${workType}/images`,
      { images: imageIds },
    )
  }

  public uploadImage(file: FileList | File): Observable<IdResponse> {
    const formData = new FormData();

    if (file instanceof FileList) {
      formData.append('file', file.item(0));
    } else {
      formData.append('file', file);
    }

    return this.http.post<IdResponse>(`${API_URL_V2}/images`, formData).pipe(
      map(response => {
        if (response && response.id) {
          this.ids.push(response.id);
        }

        return response;
      }),
    );
  }

  getPath = (id: number) => `${API_URL_V2}/images/${id}/show`;
}
