import { Injectable } from '@angular/core';
import {
  NbToastrService,
} from '@nebular/theme';
import { EServerErrors } from '../../../types/EServerErrors';
import { V2ServerError } from '../../../types';
import { HttpErrorResponse } from '@angular/common/http';

const commonErrText = "К сожалению, произошла ошибка. Пожалуйста, отправьте сообщение об этом через форму обратной связи";

const ErrorMessages = {
  '/api/clients': {
    500: commonErrText,
    406: 'Клиент с таким именем уже существует',
  },
  '/api/auth/login': {
    500: commonErrText,
    401: "Неверный пароль",
    404: "Пользователь не найден",
  },
  'others': {
    500: commonErrText,
    201: "Неверный формат файла, проверьте загруженный чек",
    400: "Упс, что-то пошло не так",
    401: "Пользователь не авторизован",
    403: "Пользователь не авторизован",
    404: "Упс, что-то пошло не так",
    417: "Не валидные введенные данные",
  },
};

const ERROR_SHOW_DURATION = 1000 * 30

@Injectable({
  providedIn: 'root',
})
export class HttpErrorHandlerService {
  constructor(
    private toastrService: NbToastrService,
  ) {
  }

  showMessage(url: string, status: number, messageFromServer?: string) {
    // Чистим протухший токен в storage
    if (url === '/api/users/current') {
      console.log("UNAUTHENTICATED. CLEARING TOKEN");
      localStorage.clear();
      return;
    }

    if (this.skipByUrlAndStatus(url, status)) return

    if (status === EServerErrors.preconditionFailed && messageFromServer) {
      this.toastrService.show(
        messageFromServer,
        '',
        { status: 'danger', hasIcon: false, duration: ERROR_SHOW_DURATION },
      );
      return;
    }

    if (status === EServerErrors.expectFailed && messageFromServer) {
      this.toastrService.show(
        messageFromServer,
        '',
        { status: 'danger', hasIcon: false, duration: ERROR_SHOW_DURATION },
      );
      return;
    }

    let message = '';

    if (messageFromServer) {
      message = messageFromServer
    } else {
      message = this.getDefaultErrorMsg(url, status)
      // Well we don't want to show empty toast message, do we? :)
      if (!message) return;
    }

    this.toastrService.show(message, 'Ошибка !', { status: 'danger', hasIcon: false, duration: ERROR_SHOW_DURATION });
  }

  private getDefaultErrorMsg(url: string, status: number): string {
    return (ErrorMessages[url] && ErrorMessages[url][status]) || ErrorMessages['others'][status];
  }

  private skipByUrlAndStatus(url: string, status: number): boolean {
    if (status === EServerErrors.notFound) {
      if (/images\/\d*\/show/.test(url)) return true
    }

    if (status === EServerErrors.badRequest) {
      if (/orders\/\d*\/approve/.test(url)) return true
    }

    if (status === EServerErrors.forbidden || status === EServerErrors.unathorized) {
      if (/orders\/\d*\/approve/.test(url)) return true
      if (/orders\/\d*\/comments/.test(url)) return true
      if (/orders\/\d*\/allowed-to-comments/.test(url)) return true
    }

    return false
  }

  public readBlobError(err: HttpErrorResponse, url: string, status: number): void {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        try {
          const result = JSON.parse(e.target.result)
          this.showV2ServerError(result, url, status)
        } catch (error) {
          console.error('Failed to parse blob error response to json');
        }
      };
      reader.readAsText(err.error)
  }

  public showV2ServerError(error: V2ServerError, url: string, status: number): void {
    console.log({error, url, status})
    if (this.skipByUrlAndStatus(url, status)) return

    const msg = this.getErrorMsg(error)
    if (msg) {
      this.toastrService.show(msg, 'Ошибка !', { status: 'danger', hasIcon: false, duration: ERROR_SHOW_DURATION });
    } else {
      const defaultMsg = this.getDefaultErrorMsg(url, EServerErrors.badRequest)
      this.toastrService.show(defaultMsg, 'Ошибка !', {
        status: 'danger',
        hasIcon: false,
        duration: ERROR_SHOW_DURATION
      });
    }
  }

  private getErrorMsg(error: V2ServerError): string {
    if (typeof error !== 'object') return ''

    let result = ''

    for (const key of Object.keys(error)) {
      switch (key) {
        case 'non_field_errors':
        case 'detail':
          result += error[key]
          break
        case 'message': {
          const isArray = Array.isArray(error[key])
          const msg = isArray ? error[key].join(', ') : error[key]
          result += `${msg}`
          break
        }
        default: {
          result += `${key}: ${error[key]}; `
        }
      }
    }

    return result
  }
}
