import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'countInArray',
  pure: true,
})
export class CountInArrayPipe implements PipeTransform {
  transform(value: any[], propName?: any): any {
    if (value && value.length > 0) {
      return value.filter(item => item[propName]).length;
    }
    return 0;
  }
}
