import {
  Pipe,
  PipeTransform
} from '@angular/core';

@Pipe({
  name: 'getName',
  pure: true
})
export class GetName implements PipeTransform {
  transform(value: number, type: any): any {
    return this.getName(value, type);
  }

  getName(entity, type) {
    // Могут быть еще типы
    switch (type) {
      case 'users':
        const {
          first_name,
          last_name,
          middle_name,
        } = entity;

        if (!last_name && !first_name && !middle_name) {
          return entity.email;
        }

        return `${last_name} ${first_name} ${middle_name}`;
      default:
        return entity.name;
    }
  }
}
