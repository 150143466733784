import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { OrdersViewComponent } from '../../../pages/orders/components/orders-view/orders-view.component';
import { AuthService } from '../../../services/auth/auth.service';

@Component({
  selector: 'ngx-mobile-order-list-item',
  templateUrl: './mobile-order-list-item.component.html',
  styleUrls: ['./mobile-order-list-item.component.scss'],
})
export class MobileOrderListItemComponent {
  @Input() public item: any;

  @Output() public goTo = new EventEmitter();

  constructor(
    private dialogService: NbDialogService,
  ) { }

  public handleGoToDetail(): void {
    this.goTo.emit(this.item);
  }
}
