import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NbToastrService, NbDialogRef } from '@nebular/theme';
import { Router, ActivatedRoute } from '@angular/router';

import { CrudService } from '../../../services/crud/crud.service';
import { AuthService } from '../../../services/auth/auth.service';
import { BaseStoreFormComponent } from './base-stores-form.component';
import { FormErrorsService } from '../../../services/form-errors/form-errors.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'ngx-stores-form-modal',
  templateUrl: './stores-form.component.html',
  styleUrls: ['./stores-form.component.scss'],
})
export class StoresFormModalComponent extends BaseStoreFormComponent {
  constructor(
    public dialogRef: NbDialogRef<StoresFormModalComponent>,
    public fb: FormBuilder,
    public toastr: NbToastrService,
    public router: Router,
    public route: ActivatedRoute,
    public crudService: CrudService,
    public authService: AuthService,
    public errorsService: FormErrorsService,
    public http: HttpClient,
  ) {
    super(fb, toastr, router, route, crudService, authService, errorsService, http);
  }

  handleSuccess = response => {
    if (response) {
      this.toastr.show('Сохранена!', 'Торговая точка', { status: 'primary'});

      this.dialogRef.close(response);
    }

    this.isLoading = false;
  }
}
