import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ngx-date-cell',
  template: `
  <div>
    {{isValueExist ? (value | date: 'dd.MM.y') : '-'}}
  </div>
  `,
})
export class DateCellComponent implements OnInit {
  public isValueExist = false;

  @Input()
  public value;

  @Input()
  public rowData;

  constructor() { }

  ngOnInit() {
    this.isValueExist = !!this.value;
  }

}
