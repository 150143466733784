import { Component, OnInit, Input } from '@angular/core';
import { BagWithPosmName } from '../../../../../types';

@Component({
  selector: 'ngx-posm-cell',
  template: `
    {{renderValue}}
  `
})
export class PosmCellComponent implements OnInit {
  renderValue = 'Отсутствует';

  @Input() value: BagWithPosmName;
  constructor() { }

  ngOnInit() {
    if (this.value && this.value.items.length > 0) {
      this.renderValue = this.value.items
        .map(({ posm: { name } }) => name)
        .join(',');
    }
  }

}
