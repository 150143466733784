import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import get from 'lodash/get';
import head from 'lodash/head';
import includes from 'lodash/includes';

import { environment } from '../../../environments/environment';
import { LoginRequest, TokenResponse, UserResponse } from '../../../types';
import { WebViewHelper } from '../../../utils';

const API_URL_V2 = environment.apiUrlV2;
const TOKEN_KEY = 'ACTION_TOKEN';

interface ISettings {
  use_camera_for_photos: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _user$: BehaviorSubject<UserResponse> = new BehaviorSubject<UserResponse>(null);

  private _token$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private token;
  private userData;

  constructor(
    private http: HttpClient,
  ) { }

  get authToken() {
    return this._token$.asObservable();
  }

  get user(): Observable<UserResponse> {
    return this._user$.asObservable();
  }

  public get isAdmin(): Observable<boolean> {
    return this.user.pipe(
      map(user => includes(
        get(user, 'roles'),
        'SUPER_ADMIN',
      )),
    );
  }

  public get isInstaller(): Observable<boolean> {
    return this.user.pipe(
      map(user => includes(
        get(user, 'roles'),
        'INSTALLER',
      )),
    );
  }

  get role() {
    return this.user.pipe(
      map(user => head(
        get(user, 'roles'),
      )),
    );
  }

  get roleId() {
    return this.user.pipe(
      map(user => ({role: head(get(user, 'roles')), id: get(user, 'id')})),
    );
  }

  public get isSeniorInstaller(): Observable<boolean> {
    return this.role.pipe(map(role => role === 'SENIOR_INSTALLER'));
  }

  get isClient () {
    return this.role.pipe(
      map(role => role === 'CLIENT'),
    );
  }

  get isAction () {
    return this.role.pipe(
      map(role => role === 'ACTION'),
    );
  }

  deleteAccount(payload: LoginRequest): Observable<any> {
    return this.http.post<Observable<any>>(`${API_URL_V2}/users/deactivate`, payload)
  }

  useCameraForPhotos$(): Observable<boolean> {
    return this.http.get<ISettings>(`${API_URL_V2}/settings`)
      .pipe(map(settings => settings.use_camera_for_photos))
  }

  loadCurrentUser(): Observable<UserResponse> {
    return this.http.get<UserResponse>(`${API_URL_V2}/users/current`).pipe(
      map(response => {
        if (response) {
          this._user$.next(response);
          this.userData = response;
        }

        return response;
      }),
    );
  }

  login(payload: LoginRequest): Observable<TokenResponse> {
    return this.http.post<TokenResponse>(`${API_URL_V2}/auth/login`, { ...payload }).pipe(
      map(response => {
        if (response && response.token) {
          this.setToken(response.token);

          WebViewHelper.saveAuthToken(response.token);
        }
        return response;
      }),
    );
  }

  public clearToken(): void {
    localStorage.removeItem(TOKEN_KEY)
  }

  public setToken(token?: string) {
    this.token = token;

    if (localStorage) {
      localStorage.setItem(TOKEN_KEY, token);
    }

    this._token$.next(token);
  }

  public isAuthenticated() {
    return !!this.token || (localStorage && !!localStorage.getItem(TOKEN_KEY));
  }

  public getToken() {
    return this.token || (localStorage && localStorage.getItem(TOKEN_KEY));
  }

  public getUserData(): UserResponse | null {
    return this.userData || null;
  }

  public getUserID(): number | undefined {
    return this.userData ? this.userData.id : undefined;
  }

  public logout() {
    WebViewHelper.saveAuthToken('NONE');

    if (localStorage) {
      localStorage.removeItem(TOKEN_KEY);
    }

    this.token = null;

    window.location.href = '/auth/login';
  }

}

