// import * as Sentry from '@sentry/browser';
import { FormGroup, ValidationErrors } from '@angular/forms';
import moment from 'moment';
import { DATE_FORMAT } from '../const';

export const addBizDays = (date, n) => {
  const num = Math.abs(n);
  const dir = (n < 0) ? -1 : 1;

  let D = date;
  let tem,
    count = 0;


  while (count < num) {
    D = new Date(D.setDate(D.getDate() + dir));

    if (D.getDay() === 0 || D.getDay() === 6) continue;

    tem = D.getDay();

    if (tem !== 0 && tem !== 6) ++count;
  }

  return D;
};

export const RolesTranslations = {
  'CLIENT': 'Клиент',
  'COORDINATOR': 'Координатор',
  'INSTALLER': 'Установщик',
  'ACTION': 'Action',
  //nebular nb-select bug for this exact option
  'SENIOR_INSTALLER_BUG': 'Старший установщик',
  // bug end
  'SUPER_ADMIN': 'Администратор',
};

export const parseUrl = url => {
  const match = url.match(/^(https?\:)\/\/(([^:\/?#]*)(?:\:([0-9]+))?)([\/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/);

  return match && {
      url,
      protocol: match[1],
      host: match[2],
      hostname: match[3],
      port: match[4],
      pathname: match[5],
      search: match[6],
      hash: match[7],
  };
};

export const declOfNum = (number, titles) => {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[
    (number % 100 > 4 && number % 100 < 20)
      ?
      2
      :
      cases[(number % 10 < 5) ? number % 10 : 5]
  ];
};

export const isSameValues = (a, b) => a === b;

export const comparePassword = (group: FormGroup) => {
  const pass = group.controls.password.value;
  const confirmPass = group.controls.confirmPassword.value;

  return pass === confirmPass ? null : { notSame: true };
};

export const atLeastOneOf = (...fields: string[]) => {
  return (fg: FormGroup): ValidationErrors | null => {
    return fields.some(fieldName => {
      const field = fg.get(fieldName).value;
      if (typeof field === 'number') return field && field >= 0 ? true : false;
      if (typeof field === 'string') return field && field.length > 0 ? true : false;
    })
      ? null
      : ({ atLeastOne: 'At least one field has to be provided.' } as ValidationErrors);
  };
};

// 2024-08-15
export function dateToString(date: moment.Moment, format = DATE_FORMAT): string {
  return moment(date).isValid() ? moment(date).format(format) : ''
}

export function getLocation(): Promise<Position | undefined> {
  return new Promise((res, rej) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(res, rej);
    } else {
      console.error("Geolocation is not supported by this browser.");
      rej(undefined)
    }
  })
}

export const isMobileDevice = () => {
  return (function(a){
    return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i
      .test(a.substr(0, 4));
  }
  )(navigator.userAgent || navigator.vendor);
};

export const getValueFormPushMessage = (message: string) => message.substring(message.indexOf(':') + 1);

export const UNINSTALL_NANE = 'UNINSTALL';
export const REPAIR_NAME = 'REPAIR';
export const INSTALL_NANE = 'INSTALL';

export const worksTypes = [
  {
    id: INSTALL_NANE,
    name: 'Монтаж',
  },
  {
    id: UNINSTALL_NANE,
    name: 'Демонтаж',
  },
  {
    id: 'REPAIR',
    name: 'Ремонт',
  },
];

export const utilizationTypes = [
  { id: 'BY_STORE', name: 'Оставить на точке' },
  { id: 'BY_INSTALLER', name: 'Утилизация установщиком' },
];

export const orderErrorsByField = {
  program: 'Не выбрана программа',
  name: 'Не заполнено имя',
  store: 'Не выбрана торговая точка',
  worksType: 'Не выбран вид работы',
  posmsFields: 'Не заполнено количество или не выбран POSM',
  client: 'Не выбран клиент',
  date: 'Не выбрана дата',
  creator_contact_information: 'Не заполнено поле инициатор заявки (контакты)',
  company_representative_contact_information: 'Не заполнено поле сотрудника (контакты)',
};

export const getWorkTypeBagName = (workType: string) => `${workType.toLowerCase()}_bag`;

function timeToString(num: number): string {
  return String(num).length === 1 ? `0${num}` : String(num)
}

export const timeConvertToString = (num: number): string => {
 const hours = Math.floor(num / 60);
 const minutes = num % 60;

 return timeToString(hours) + ':' + timeToString(minutes);
};

export const timeConvertToHuman = (num: number): string => {
  if (num === undefined) return ''

  const absNum = Math.abs(num);
  const hours = Math.floor(absNum / 60) === 0 ? '00' : Math.floor(absNum / 60);
  const minutes = absNum % 60;
  const minus = num < 0 ? '- ' : '';

  return `${minus}${hours} ч. ${minutes} мин.`;
 };

export const stringConvertToTime = value => value
  .split(':')
    .reduce((acc, time) => (60 * acc) + Number(time));

/**
 * @deprecated The interface is deprecated. Remove after migration
 */
export const discrTranslate = {
  route_part_take_from_warehouse: 'Получить на складе',
  route_part_store_in_store: 'Монтаж POSM',
  route_part_repair_in_store: 'Ремонт POSM',
  route_part_take_from_store: 'Демонтаж POSM',
  route_part_store_in_warehouse: 'Отгрузить на склад',
  route_part_delivery_take_from_store: 'Забрать из торговой точки',
  route_part_delivery_take_from_warehouse: 'Забрать со склада',
  route_part_delivery_store_in_store: 'Доставить в торговую точку',
  route_part_delivery_store_in_warehouse: 'Доставить на склад',
};

export enum EPointType {
  'store' = 'Store',
  'warehouse' = 'Warehouse',
}

export const discrToWorkType = {
  route_part_delivery: 'delivery',
  route_part_delivery_store_in_store:	'delivery',
  route_part_delivery_store_in_warehouse:	'delivery',
  route_part_delivery_take_from_store:	'delivery',
  route_part_delivery_take_from_warehouse:	'delivery',
  route_part_store_in_store:	'install',
  route_part_take_from_store:	'uninstall',
  route_part_repair_in_store:	'repair',
}

export const discrToWorkTypeTranslation = {
  route_part_delivery:	'Доставка',
  route_part_delivery_store_in_store: 'Доставка',
  route_part_delivery_store_in_warehouse: 'Доставка',
  route_part_delivery_take_from_store: 'Доставка',
  route_part_delivery_take_from_warehouse: 'Доставка',
  route_part_store_in_store:	'Монтаж',
  route_part_take_from_store:	'Демонтаж',
  route_part_repair_in_store:	'Ремонт',
}

export const getEntityType = (type: string) => type.includes('warehouse') ? 'warehouse' : 'store';

export const getExchangeType = (type: string) => {
  const takes = [
    'route_part_take_from_warehouse',
    'route_part_take_from_store',
    'route_part_delivery_take_from_store',
    'route_part_delivery_take_from_warehouse',
  ];
  const stores = [
    'route_part_store_in_warehouse',
    'route_part_store_in_store',
    'route_part_repair_in_store',
    'route_part_delivery_store_in_store',
    'route_part_delivery_store_in_warehouse',
  ];

  if (takes.includes(type)) return 'take';
  if (stores.includes(type)) return 'store';
};

export const discrTranslateActions = {
  'route_part_take_from_warehouse': 'Забрать',
  'route_part_store_in_store': 'Монтаж',
  'route_part_repair_in_store': 'Ремонт',
  'route_part_take_from_store': 'Демонтаж',
  'route_part_store_in_warehouse': 'Отгрузить',
  'route_part_delivery_take_from_store': 'Забрать',
  'route_part_delivery_take_from_warehouse': 'Забрать',
  'route_part_delivery_store_in_store': 'Доставить',
  'route_part_delivery_store_in_warehouse': 'Доставить',
};

export const getTranslateWorkTypeByDiscr = (type: string) => discrTranslateActions[type];


/**
 * FUNCTIONS AND HELPERS FOR DEVICE COMMUNICATION WITHIN
 * WEBVIEW
 *
 * START
 */

export class WebViewHelper {
  public static getAuthToken() {
    // Sentry.captureMessage('TEST ERROR -  GET AUTH TOKEN');
    sendMessageToDevice('getAuthToken');
  }

  public static saveAuthToken(token: string) {
    sendMessageToDevice('saveAuthToken', token);
  }

  public static getGeoLocation() {
    sendMessageToDevice('getGeoLocation');
  }

  public static registerPush() {
    sendMessageToDevice('registerPush');
  }

  public static openCamera() {
    sendMessageToDevice('openCamera');
  }
}

declare var webkit: any;
declare var JSInterface: any;


export function sendMessageToDevice(funcName: string, data?: string) {
  try {
    if (typeof webkit !== 'undefined') {
      webkit.messageHandlers[funcName].postMessage(!!data ? data : 'ANYTHING');
    } else if (typeof JSInterface !== 'undefined') {
      if (!!data) {
        JSInterface[funcName](data);
      } else {
        JSInterface[funcName]();
      }
    }
  } catch (error) {}
}
/**
 * FUNCTIONS AND HELPERS FOR DEVICE COMMUNICATION WITHIN
 * WEBVIEW
 *
 * END
 */

export const getUniqueName = () => `${+new Date()}-${Math.random().toString(36).substr(2, 9)}`;

export function mapToResults<T = any[]>(response): T { return response['results'] }

export function isHostOnly(): boolean {
  return window?.location?.pathname === '' || window?.location?.pathname === '/'
}
