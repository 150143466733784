import { Component, OnInit, Input } from '@angular/core';
import { StoresResponse, OrderResponse } from '../../../../../types';

@Component({
  selector: 'ngx-city-cell',
  template: '{{renderValue}}',
})
export class CityCellComponent implements OnInit {
  @Input() value: string;
  @Input() rowData: OrderResponse;

  renderValue = '';

  public ngOnInit(): void {
    this.renderValue = (this.rowData.store && this.rowData.store.city && this.rowData.store.city.name) || '';
  }
}
