import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ngx-created-at-cell',
  template: `
  <div>
    {{value | date: 'dd.MM.y'}}
  </div>
  <div>
    {{rowData.created_by_user?.name}}
  </div>
  `,
})
export class CreatedAtCellComponent implements OnInit {
  @Input()
  public value;

  @Input()
  public rowData;

  constructor() { }

  ngOnInit() {
  }

}
