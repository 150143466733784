import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';


@Component({
  selector: 'ngx-accept-execution',
  templateUrl: './accept-execution.component.html',
  styleUrls: ['./accept-execution.component.scss']
})
export class AcceptExecutionComponent implements OnInit {
  @Input() public posm: any;

  count: number;

  constructor(protected dialogRef: NbDialogRef<AcceptExecutionComponent>) { }

  public save(): void {
    const { count } = this;
    if (!count) return;
    this.dialogRef.close({
      count,
    });
  }

  public ngOnInit(): void {
    this.count = this.posm.count
  }
}
