import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Subscription, Observable, BehaviorSubject, of } from 'rxjs';
import { NbToastrService } from '@nebular/theme';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { CrudService } from '../../../services/crud/crud.service';
import {
  StoresResponse,
  AddressRequest,
  ClientsResponse,
  AddressFromModal,
  RetailChain,
  TariffZone, ServerResponse,
} from '../../../../types';
import { CREATE_TEXT, UPDATE_TEXT } from '../../../../const';
import { AuthService } from '../../../services/auth/auth.service';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap, map } from 'rxjs/operators';
import { FormErrorsService } from '../../../services/form-errors/form-errors.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { mapToResults } from '../../../../utils';

const API_URL_V2 = environment.apiUrlV2

@Component({
  selector: 'ngx-base-stores-form',
  templateUrl: './stores-form.component.html',
  styleUrls: ['./stores-form.component.scss'],
})
export class BaseStoreFormComponent implements OnInit, OnDestroy {
  table;

  selectedCity;
  selectedTariffZone;
  selectedSecondaryCity;

  yMapAddress;
  isLoading = false;
  showModal = false;

  subAuth: Subscription;
  subWarehouse: Subscription;
  subRoute: Subscription;

  lat: number
  long: number
  selectedGeoFromList = false

  form: FormGroup;
  routeId: string;
  actionText = CREATE_TEXT;

  isAdmin$: Observable<boolean> = this.authService.isAdmin;
  clients$: Observable<ClientsResponse[]> = this.crudService
    .getV2<ClientsResponse[]>('/clients')
    .pipe(map(mapToResults))

  cities$: Observable<ClientsResponse[]> = this.crudService
    .getV2<ClientsResponse[]>('/cities?first-level')
    .pipe(map(mapToResults))

  secondaryCities$: Observable<ClientsResponse[]> = this.crudService
    .getV2<ClientsResponse[]>('/cities?second-level')
    .pipe(map(mapToResults))

  tariffZones$: Observable<TariffZone[]> = this.crudService
    .getV2<TariffZone[]>('/tariff_zones?_sort=zone&_order=asc')
    .pipe(map(mapToResults))

  isNew = true

  selectedRetailChain;
  searchRetailChainsInput$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  retailChains: RetailChain[] = [];
  loadingRetainChains = false;

  constructor(
    public fb: FormBuilder,
    public toastr: NbToastrService,
    public router: Router,
    public route: ActivatedRoute,
    public crudService: CrudService,
    public authService: AuthService,
    public errorsService: FormErrorsService,
    public http: HttpClient,
  ) {
    this.searchRetailChainsInput$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      tap(() => this.loadingRetainChains = true),
      switchMap(term => {
        return this.crudService.getV2<RetailChain[]>(
          `/retail_chains?_sort=name&_order=asc&name_like=${term ? term : ''}`,
        ).pipe(
          map(mapToResults),
          catchError(() => of([])),
          tap(() => this.loadingRetainChains = false),
        )
      }),
    ).subscribe(items => this.retailChains = items)

    this.form = this.fb.group({
      name: ['', [Validators.required]],
      addressName: ['', [Validators.required]],
      client: ['', [Validators.required]],
      pointCode: [''],
      externalCode: [],
    });
  }

  handleSuccess = response => {

    if (response) {
      this.toastr.show('Сохранена!', 'Торговая точка', { status: 'primary'});
      this.router.navigateByUrl('pages/stores/list');
    }

    this.isLoading = false;
  }

  handleError = () => this.isLoading = false;

  public onSubmit(): void {
    if (this.form.valid && this.selectedCity) {
      this.isLoading = true;

      const values = {
        address_lat: this.lat,
        address_long: this.long,
        address_name: this.form.get('addressName').value,
        external_code: this.form.get('externalCode').value,
        point_code: this.form.get('pointCode').value,
        retail_chain: {
          id: this.selectedRetailChain,
        },
        tariff_zone: {
          id: this.selectedTariffZone,
        },
        city: {
            id: +this.selectedCity,
        },
        client: {
          id: +this.form.get('client').value,
        },
        name: this.form.get('name').value,
      };

      if (!this.selectedRetailChain) delete values.retail_chain;
      if (!this.selectedTariffZone) delete values.tariff_zone;

      if (!values.address_lat) delete values.address_lat
      if (!values.address_long) delete values.address_long

      if (this.selectedSecondaryCity) {
        values['secondary_city'] = { id: +this.selectedSecondaryCity };
      }

      if (!this.routeId) {
        this.crudService
          .createV2<AddressRequest>(values, '/stores')
          .subscribe(this.handleSuccess, this.handleError);
      } else {
        this.crudService
          .updateV2<AddressRequest>(values, `/stores/${this.routeId}`)
          .subscribe(this.handleSuccess, this.handleError);
      }
    }
  }


  setAddress = (geo: AddressFromModal) => {
    this.lat = geo.addressLat
    this.long = geo.addressLong
    this.showModal = false;
    this.form.controls['addressName'].setValue(geo.addressName);
  }

  handleSelect = async event => {
    try {
      this.selectedGeoFromList = true
      const response = await ymaps.geocode(event.get('item').value);
      [this.lat, this.long] = response.geoObjects.get(0).geometry.getCoordinates()
      this.form.controls['addressName'].setValue(event.get('item').value);
    } catch (error) {
      console.error(error);
    }
  }

  setFormValues = ({ addressName, name, client, pointCode, externalCode }) => {
    this.form.controls['addressName'].setValue(addressName);
    this.form.controls['name'].setValue(name);
    this.form.controls['client'].setValue(`${client}`);
    this.form.controls['pointCode'].setValue(`${pointCode || ""}`);
    this.form.controls['externalCode'].setValue(`${externalCode || ""}`);
  }

  private addRetailChainById(id: number): Promise<void | RetailChain> {
    return this.retailChains.find(ch => ch.id === id)
      ? of<void>().toPromise<void>()
      : this.crudService.getV2<RetailChain>(`/retail_chains/${id}`)
        .pipe(tap(item => {
          this.retailChains = this.retailChains.concat((item))
        })).toPromise()
  }

  public ngOnInit(): void {
    this.form.get('addressName').valueChanges.subscribe(address => {
      // отличаем ручной ввод и выбор из списка yamaps suggest view
      if (!this.selectedGeoFromList) {
        this.long = this.lat = undefined
      } else {
        this.selectedGeoFromList = false
      }
    })

    this.subAuth = this.authService.user.subscribe((data) => {
      if (data && data.client && data.client.id) {
        this.form.controls['client'].setValue(`${data.client.id}`);
      }
    });

    this.yMapAddress = new ymaps.SuggestView('address');
    this.yMapAddress.events.add('select', this.handleSelect);

    this.subRoute = this.route.paramMap.subscribe((params: ParamMap) => {
      const id = params.get('id');

      if (id) {
        this.isNew = false;
        this.actionText = UPDATE_TEXT;
        this.routeId = id;
        this.subWarehouse = this.crudService.getV2<StoresResponse>(`/stores/${id}`)
          .subscribe(response => {
            if (response) {
              this.setFormValues({
                addressName: response.address_name,
                name: response.name,
                client: response.client.id,
                pointCode: response.point_code,
                externalCode: response.external_code,
              });
              setTimeout(async () => {
                if (response?.retail_chain?.id) {
                  await this.addRetailChainById(response.retail_chain.id)
                }
                this.selectedRetailChain = response?.retail_chain?.id
                this.selectedTariffZone = response?.tariff_zone?.id + ""
                this.selectedCity = response.city.id + ""
                this.selectedSecondaryCity = response.secondary_city.id + ""
              }, 500)
            }
          });
      }
    });
  }

  public removeTT(): boolean {
    if (window.confirm("Вы уверены что хотите удалить Торговую точку?")) {
      this.http.delete<ServerResponse>(
        `${API_URL_V2}/stores/${this.routeId}`,
        { headers: { 'Authorization': `Bearer ${this.authService.getToken()}` } },
      ).subscribe(() => {
        this.toastr.success('', 'Успешно', { duration: 8000 });
        this.router.navigateByUrl('pages/stores').then();
      })
    }
    return false
  }

  ngOnDestroy() {
    this.subRoute && this.subRoute.unsubscribe();
    this.yMapAddress && this.yMapAddress.events.remove('select', this.handleSelect);
    this.subAuth && this.subAuth.unsubscribe();
  }
}
